import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse, List, ListItem, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const hasChildren = (item) => {
  const { items: children } = item

  if (children === undefined) {
    return false
  }

  if (children.constructor !== Array) {
    return false
  }

  if (children.length === 0) {
    return false
  }

  return true
}

const MultiLevel = ({ item, updateDefinitionLink }) => {
  const { items: children } = item
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  return (
    <>
      <ListItem onClick={handleClick} className='parent-link'>
        <ListItemText primary={item.title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding className='multi-links'>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} updateDefinitionLink={updateDefinitionLink} />
          ))}
        </List>
      </Collapse>
    </>
  )
}

MultiLevel.propTypes = {
  item: PropTypes.array,
  updateDefinitionLink: PropTypes.func
}

const MenuItem = ({ item, updateDefinitionLink }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel
  return <Component item={item} updateDefinitionLink={updateDefinitionLink} />
}

const SingleLevel = ({ item, updateDefinitionLink }) => {
  return (
    <ListItem className='child-link' onClick={() => updateDefinitionLink(item.url)}>
      <ListItemText primary={item.title} />
    </ListItem>
  )
}

MenuItem.propTypes = {
  item: PropTypes.array,
  updateDefinitionLink: PropTypes.func
}

SingleLevel.propTypes = {
  item: PropTypes.array,
  updateDefinitionLink: PropTypes.func
}

const Sidebar = ({
  menu,
  updateDefinitionLink
}) => {
  return (
    menu.map((item, key) => <MenuItem key={key} item={item} updateDefinitionLink={updateDefinitionLink} />)
  )
}

Sidebar.propTypes = {
  updateDefinitionLink: PropTypes.func
}

export default Sidebar
