export const MENU_LIST = [
  {
    title: 'Artist',
    id: 'artist',
    items: [{
      title: 'v2.5',
      url: '/docs/v2.5/artists/swagger.yml'
    }]
  },
  {
    title: 'Dates',
    items: [
      {
        title: 'v2.5',
        url: '/docs/v2.5/dates/swagger.yml'
      },
      {
        title: 'v3.0',
        url: '/docs/v3.0/dates/swagger.yml'
      }
    ]
  },
  {
    title: 'Events',
    items: [
      {
        title: 'v2.5',
        url: '/docs/v2.5/events/swagger.yml'
      },
      {
        title: 'v3.0',
        url: '/docs/v3.0/events/swagger.yml'
      }
    ]
  },
  {
    title: 'PromoCodes',
    items: [
      {
        title: 'v2.5',
        url: '/docs/v2.5/promo_codes/swagger.yml'
      },
      {
        title: 'v3.0',
        url: '/docs/v3.0/promo_codes/swagger.yml'
      }
    ]
  },
  {
    title: 'Series',
    items: [
      {
        title: 'v2.5',
        url: '/docs/v2.5/series/swagger.yml'
      },
      {
        title: 'v3.0',
        url: '/docs/v3.0/series/swagger.yml'
      }
    ]
  },
  {
    title: 'Static Data',
    items: [
      {
        title: 'v2.5',
        url: '/docs/v2.5/static_data/swagger.yml'
      },
      {
        title: 'v3.0',
        url: '/docs/v3.0/static_data/swagger.yml'
      }
    ]
  },
  {
    title: 'Venues',
    items: [
      {
        title: 'v2.5',
        url: '/docs/v2.5/venues/swagger.yml'
      },
      {
        title: 'v3.0',
        url: '/docs/v3.0/venues/swagger.yml'
      }
    ]
  }
]

export const API_LINKS = [
  {
    name: 'Artist',
    id: 'artists',
    list: [{
      name: 'v2.5',
      url: '/docs/v2.5/artists/swagger.yml'
    }]
  },
  {
    name: 'Dates',
    id: 'dates',
    list: [{
      name: 'v2.5',
      url: '/docs/v2.5/dates/swagger.yml'
    }, {
      name: 'v3.0',
      url: '/docs/v3.0/dates/swagger.yml'
    }]
  },
  {
    name: 'Event',
    id: 'events',
    list: [{
      name: 'v2.5',
      url: '/docs/v2.5/events/swagger.yml'
    }, {
      name: 'v3.0',
      url: '/docs/v3.0/events/swagger.yml'
    }]
  },
  {
    name: 'Promo Codes',
    id: 'promo_codes',
    url: '/docs/v2.5/promo_codes/swagger.yml',
    list: [{
      name: 'v2.5',
      url: '/docs/v2.5/promo_codes/swagger.yml'
    }, {
      name: 'v3.0',
      url: '/docs/v3.0/promo_codes/swagger.yml'
    }]
  },
  {
    name: 'Series',
    id: 'series',
    url: '/docs/v2.5/series/swagger.yml',
    list: [{
      name: 'v2.5',
      url: '/docs/v2.5/series/swagger.yml'
    }, {
      name: 'v3.0',
      url: '/docs/v3.0/series/swagger.yml'
    }]
  },
  {
    name: 'Static Data',
    id: 'static_data',
    url: '/docs/v2.5/static_data/swagger.yml',
    list: [{
      name: 'v2.5',
      url: '/docs/v2.5/static_data/swagger.yml'
    }, {
      name: 'v3.0',
      url: '/docs/v3.0/static_data/swagger.yml'
    }]
  },
  {
    name: 'Venue',
    id: 'venues',
    list: [{
      name: 'v2.5',
      url: '/docs/v2.5/venues/swagger.yml'
    }, {
      name: 'v3.0',
      url: '/docs/v3.0/venues/swagger.yml'
    }]
  }
]
