import React, { useState } from 'react'
import SwaggerUI from 'swagger-ui-react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'

import Sidebar from './components/Sidebar.js'
import { MENU_LIST } from './constants'

const App = () => {
  // const [definitionList] = useState(null)
  const [definitionLink, setDefinitionLink] = useState('/docs/v2.5/events/swagger.yml')

  const updateDefinitionLink = newLink => {
    setDefinitionLink(newLink)
  }

  return (
    <div className='App'>
      <CssBaseline />
      <AppBar className='material-ui-app-bar'>
        <Toolbar>
          <Typography variant='h6'>
            <img src='/msg-logo.png' alt='msg-logo' width='300' height='43' />
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container className='grid-wrapper'>
        <Grid item xs={2} className='sidebar-wrapper has-bg'>
          <Typography variant='h4'>
            APIs
          </Typography>
          <Sidebar
            menu={MENU_LIST}
            updateDefinitionLink={updateDefinitionLink}
          />
        </Grid>
        <Grid item xs={10}>
          <div id='api-data'>
            <SwaggerUI
              url={definitionLink}
              docExpansion='list'
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default App
